<template>
  <div class="zpuserbox" :style="btmbkimg_s" v-loading="zpuserboxloading">
    <div
      class="Zhanpingbanner"
      :style="bisheall.d_type == 2 ? 'height:91vh' : 'height:auto;display:block;'"
    >
      <img
        :src="
          bisheall.banner == null
            ? 'https://task.hozoin.cn/Works/20210208/sj-1419195939.png'
            : bisheall.banner
        "
        :class="bisheall.d_type == 1 ? 'bsbannerimg' : 'zpbannerimg'"
        alt=""
      />
      <img
        class="Zhanpingbannertextimg"
        v-if="bisheall.d_type == 2"
        :src="draw_info.textimg"
        alt=""
      />
      <img v-if="bisheall.d_type == 2" :src="bisheall.draw_pic" alt="" class="zpbannerimg_ios">
    </div>
    <div class="Zhanping_bishe">
      <div v-if="bisheall.d_type == 1" class="bizhezhantoptext">
        <div class="bizhezhantoptext_l">
          <div class="Zhanping_bishejianjie_allimg">
            <img :src="bisheall.draw_img" alt="" />
          </div>
          <div class="Zhanping_bishejianjie_allbox">
            <p class="Zhanping_bishejianjie">学院简介</p>
            <div
              class="Zhanping_bishejianjie_all"
              v-html="bisheall.drawtext"
            ></div>
          </div>
        </div>
        <div class="bizhezhantoptext_r" v-if="bisheall.send_word != ''">
          <div class="Zhanping_bishejianjie_allbox">
            <p class="Zhanping_bishejianjie">展览简介</p>
            <div
              class="Zhanping_bishejianjie_all"
              v-html="bisheall.send_word"
            ></div>
          </div>
          <div class="Zhanping_bishejianjie_allimg">
            <img :src="bisheall.word_img" alt="" />
          </div>
        </div>
      </div>
      <div class="Zhanping_shai" v-if="bisheall.d_type == 1">
        <div class="Zhanping_shaibox">
          <el-select
            v-model="optionsvalue"
            style="margin-right: 2%"
            placeholder="热度"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="banjioptionsval"
            style="margin-right: 2%"
            placeholder="班级"
          >
            <el-option
              v-for="(item, index) in banjioptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <el-select
            v-model="zhuanyeoptionsval"
            style="margin-right: 2%"
            placeholder="专业分类"
          >
            <el-option
              v-for="(item, index) in zhuanyeoptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="xuelioptionsval"
            style="margin-right: 2%"
            placeholder="学历选择"
          >
            <el-option
              v-for="item in xuelioptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div
            class="Zhanping_shaibtn Zhanping_shaibtnmove"
            @click="shaixunaxiosremove"
          >
            重置
          </div>
          <div class="Zhanping_shaibtn" @click="shaixunaxios">立即筛选</div>
          <el-button @click="Zhanping_fabubtn" plain class="Zhanping_fabubtn"
            >发布作品</el-button
          >
        </div>
      </div>
      <ul
        class="Zhanping_zpz_ul"
        :style="draw_color"
        v-if="bisheall.d_type == 2"
      >
        <li @click="userzpshai('')">全部</li>
        <li
          v-for="item in zhuanyeoptions"
          :key="item"
          @click="userzpshai(item)"
        >
          {{ item }}
        </li>
      </ul>
      <ul class="Zhanping_bisheul">
        <li
          class="Zhanping_bisheulli"
          v-for="item in Zhanping_bisheul"
          :key="item.title"
          @click="Zhanping_bisheulliclick(item.id)"
          :class="item.title ? '' : 'Zhanping_bisheullizero'"
        >
          <div class="Zhanping_bisheulliimg">
            <img alt="" v-lazy="item.titlepic" />
          </div>
          <p class="Zhanping_bisheulli_title">
            {{ item.title }}
          </p>
          <div class="Zhanping_bisheulli_zz">
            <div>作者：{{ item.username }}</div>
            <div v-if="item.major != 0">专业：{{ item.major }}</div>
          </div>
          <div class="Zhanping_bisheulli_zz">
            <div v-if="false">行业：{{ item.activity_one }}</div>
            <div v-if="bisheall.d_type == 1 && item.teacher != 0">
              导师：{{ item.teacher }}
            </div>
          </div>
          <div class="Zhanping_bisheulli_ll">
            <icon-svg icon-class="iconlook" />
            <span>{{ item.onclick == null ? "0" : item.onclick }}</span>
            <icon-svg icon-class="iconmessages" />
            <span>{{ item.plnum == null ? "0" : item.plnum }}</span>
            <icon-svg icon-class="icongood" />
            <span>{{ item.give_like == null ? "0" : item.give_like }}</span>
          </div>
          <div class="Zhanping_bisheulli_redu">
            <div>
              <img :src="item.userpic" alt="" />
              {{ item.username }}
            </div>
            <div>{{ item.create_time }}</div>
          </div>
        </li>
        <li class="lizero" v-if="lizero">
          <img
            src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
            alt=""
          />
        </li>
      </ul>
      <el-pagination
        :pager-count="5"
        @current-change="currentchange"
        :page-size="pagesize"
        :current-page.sync="currentpage"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      zpuserboxloading:true,
      btmbkimg_s: "",
      currentpage: 1,
      lizero: false,
      banjioptions: [],
      zhuanyeoptionsval: "",
      zhuanyeoptions: [],
      banjioptionsval: "",
      xuelioptionsval: "",
      draw_color: "",
      xuelioptions: [
        {
          label: "专科",
          value: "1",
        },
        {
          label: "本科",
          value: "2",
        },
        {
          label: "硕士",
          value: "3",
        },
      ],
      valtime: "",
      options: [
        {
          value: "1",
          label: "点赞人数最多",
        },
        {
          value: "2",
          label: "浏览人数最多",
        },
      ],
      optionstime: [
        {
          value: "1",
          label: "前 >> 后",
        },
        {
          value: "2",
          label: "后 >> 前",
        },
      ],
      optionsvalue: "",
      Zhanping_bisheul: [],
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      userinfo: {},
      bisheall: {},
      total: 0,
      pagesize: 20,
      draw_info: {},
    };
  },
  methods: {
    Zhanping_bisheulliclick(id) {
      if (this.bisheall.d_type == "2") {
        var hrefs = this.$router.resolve({
          path: "/Chengguo_cworkszp",
          query: { data: id,zpid:this.$route.query.data },
        });
      }else if (this.$route.query.data == "34") {
         this.$message({
              message: "展览已结束，应院方要求，作品不再展示！",
              duration: 5000,
            });
      } else {
        var hrefs = this.$router.resolve({
          path: "/Chengguo_c",
          query: { data: id },
        });
      };
      window.open(hrefs.href, "_blank");
    },
    zlall(id) {
      var that = this;
      this.$axios({
        url: "/api/getDrawInfo",
        method: "post",
        data: {
          id,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.bisheall = res.data.result;
          document.title = res.data.result.title + "_和作营";
          if (res.data.result.draw_info != null) {
            that.draw_info = JSON.parse(res.data.result.draw_info);
            if (that.draw_info.btmbkimg == "") {
              that.btmbkimg_s = "background:" + res.data.result.draw_background;
            } else {
              that.btmbkimg_s =
                "background-image:url(" + that.draw_info.btmbkimg + ")";
            }
          }
          this.fenxiang(
            res.data.result.title,
            res.data.result.send_word,
            res.data.result.draw_pic
          );
          if (res.data.result.draw_color != null) {
            that.draw_color = "color:" + res.data.result.draw_color;
          }
        } else {
        }
      });
    },
    axiosqq(id, page) {
      var that = this;
      that.currentpage = page;
      this.$axios({
        url: "/api/getReviewList",
        method: "post",
        data: {
          draw: id,
          page,
          limit: that.pagesize,
          optionsvalue: that.optionsvalue, //热度
          education_school: that.xuelioptionsval, //学历
          class_school: that.banjioptionsval, //班级
          major: that.zhuanyeoptionsval, //专业
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.lizero = false;
          that.Zhanping_bisheul = res.data.result.list;
          that.zpuserboxloading=false;
          that.total = res.data.result.count;
          var count = res.data.result.list.length % 4;
          var li = "";
          if (count == 2) {
            that.Zhanping_bisheul.push(li, li);
          } else if (count == 3) {
            that.Zhanping_bisheul.push(li);
          }
        } else {
          that.Zhanping_bisheul = [];
          that.total = 0;
          that.lizero = true;
          that.zpuserboxloading=false;
        }
      });
    },
    currentchange(index) {
      var id = this.$route.query.data;
      this.axiosqq(id, index);
      document.documentElement.scrollTop = 0;
    },
    shaixunaxios() {
      var id = this.$route.query.data;
      this.axiosqq(id, 1);
    },
    userzpshai(zy) {
      var id = this.$route.query.data;
      this.zhuanyeoptionsval = zy;
      this.axiosqq(id, 1);
    },
    shaixunaxiosremove() {
      this.optionsvalue = "";
      this.xuelioptionsval = "";
      this.banjioptionsval = "";
      this.zhuanyeoptionsval = "";
      var id = this.$route.query.data;
      this.axiosqq(id, 1);
    },
    banjiaxios(d_id) {
      this.$axios({
        url: "/api/getDrawClassList",
        method: "post",
        data: {
          d_id,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          if (res.data.result[0].class_school != "") {
            this.banjioptions = JSON.parse(res.data.result[0].class_school);
          }
          if (res.data.result[0].major_school != "") {
            this.zhuanyeoptions = JSON.parse(res.data.result[0].major_school);
            console.log(this.zhuanyeoptions);
          }
        } else {
        }
      });
    },
    Zhanping_fabubtn() {
      if (this.userinfo != null) {
        this.$router.push({ path: "/Sjfabu" });
      } else {
        this.$router.push({ path: "/Sjlogin" });
      }
    },
  },
  created() {
    var that = this;
    var id = that.$route.query.data;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.zlall(id);
    this.banjiaxios(id);
    this.axiosqq(id, 1);
  },
  watch: {},
};
</script>
<style>
@import "../assets/css/zhanping.css";
</style>
